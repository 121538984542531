import React, { useEffect, useState } from "react";
import signinImg from "../images/signinImg.png";
import signinlogo from "../images/signinlogo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { message, Spin } from "antd";
import BaseService from "../../helpers/baseServices";
import { global_variables } from "../../helpers/globalVariables";
import { parse } from "querystring";


const ForgotPassword = () => {

    const location = useLocation();
    const [appref, setAppref] = useState<any>("")

    const navigate = useNavigate()
    const [isBusy, setIsBusy] = useState(false);
    const [email, setEmail] = useState("");

    //onload
    useEffect(() => {
        const redirect_url = parse(location.search.replace("?", ""));
        if (redirect_url) {
            setAppref(redirect_url?.appref)
        }

        // eslint-disable-next-line
    }, [])


    //handle submit function
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        let frgt_pwd_data = {
            "email": email
        }
        console.log("---ready", frgt_pwd_data)
        setIsBusy(true)

        try {
            const response = await BaseService.put_api(global_variables.forgetpassword, frgt_pwd_data)
            console.log("---forgetPwdRes:", response?.data)
            localStorage.setItem("tempToken", response?.data?.token)

            navigate(`/verify-account?appref=${appref}`)
        } catch (error: any) {
            console.log("---forgetPwdErr:", error)
            message.error(error?.response?.data?.error || "An unexpected error occured. Try again")
            setIsBusy(false)
        }

    };


    return (
        <div className="bg-defaultBlue text-white h-screen overflow-hidden">
            <div className="flex justify-center items-center h-full">
                <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-2 w-full 2xl:px-[150px] xl:px-[150px] lg:px-[100px] md:px-[70px] px-5">
                    {/* left */}
                    <div className="w-full hidden 2xl:block xl:block lg:block md:block">
                        <div className="text-left">
                            <p className="text-[42px] font-semibold">Forgot Password</p>

                            <p className="text-[16px] mt-[15px]">
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                            </p>

                            <div className="mt-[15px]">
                                <img src={signinImg} alt="" />
                            </div>
                        </div>
                    </div>

                    {/* right */}
                    <div className="w-full py-10 flex justify-center items-center">
                        <div className="flex mr-auto ml-auto px-5 2xl:w-[450px] xl:w-[400px] lg:w-[400px] md:w-[400px] w-full">
                            <div className="w-full">
                                <img
                                    className="flex mr-auto ml-auto w-[120px]"
                                    src={signinlogo}
                                    alt=""
                                />

                                {/* form */}
                                <div className="mt-10">
                                    <form onSubmit={handleSubmit}>
                                        <div>
                                            <input
                                                type="email"
                                                className="w-full bg-[#5D5DDC] text-white h-[45px] shadow-sm border-none placeholder-defaultGray focus:bg-[#5D5DDC] focus:outline-none rounded-[5px] px-5 disabled:cursor-not-allowed"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                                disabled={isBusy}
                                            />
                                        </div>

                                        <button
                                            type="submit"
                                            className="bg-defaultYellow text-white h-[50px] w-full rounded-[25px] mt-[50px] disabled:cursor-not-allowed"
                                            disabled={isBusy}
                                        >
                                            {
                                                isBusy ?
                                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                                    :
                                                    "Continue"}
                                        </button>
                                    </form>
                                </div>

                                <div className="mt-[20px] text-white">
                                    <div className="flex items-center justify-center gap-2">
                                        <p className="text-[16px]">
                                            Don't want to proceed?
                                        </p>
                                        <Link to={`${appref ? `/?appref=${appref}` : '/'}`} className="hover:text-white hover:underline">
                                            Go Back
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
