import React, { useEffect, useState } from "react";
import signinImg from "../images/signinImg.png";
import signinlogo from "../images/signinlogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import { message, Spin } from "antd";
import { global_variables } from "../../helpers/globalVariables";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { BASE_URL } from "../../helpers/constants";
import axios from "axios";
import { parse } from "querystring";


const ResetPassword = () => {

    const location = useLocation();
    const [appref, setAppref] = useState<any>("")

    const navigate = useNavigate()
    const [isBusy, setIsBusy] = useState(false)
    const [viewPwd, setViewPwd] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [confirmPwd, setConfirmPwd] = useState("")


    //onload
    useEffect(() => {
        const redirect_url = parse(location.search.replace("?", ""));
        if (redirect_url) {
            setAppref(redirect_url?.appref)
        }

        // eslint-disable-next-line
    }, [])


    //handle submit function
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (newPassword !== confirmPwd) {
            message.warning("Passwords do not match.")
        } else {

            let new_pwd_data = {
                "password": newPassword
            }
            console.log("---ready", new_pwd_data)
            setIsBusy(true)

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: `${BASE_URL}${global_variables.resetpassword}`,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem("tempToken")
                },
                data: new_pwd_data
            };

            try {
                const response = await axios.request(config)
                console.log("---resetPwdRes:", response?.data)
                message.success("Password reset successful. Login to proceed")
                navigate(`${appref ? `/?appref=${appref}` : '/'}`)
            } catch (error: any) {
                console.log("---resetPwdErr:", error)
                message.error(error?.response?.data?.error || "An unexpected error occured. Try again")
                setIsBusy(false)
            }

        }

    };


    return (
        <div className="bg-defaultBlue text-white h-screen overflow-hidden">
            <div className="flex justify-center items-center h-full">
                <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-2 w-full 2xl:px-[150px] xl:px-[150px] lg:px-[100px] md:px-[70px] px-5">
                    {/* left */}
                    <div className="w-full hidden 2xl:block xl:block lg:block md:block">
                        <div className="text-left">
                            <p className="text-[42px] font-semibold">Set a new password</p>

                            <p className="text-[16px] mt-[15px]">
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                            </p>

                            <div className="mt-[15px]">
                                <img src={signinImg} alt="" />
                            </div>
                        </div>
                    </div>

                    {/* right */}
                    <div className="w-full py-10 flex justify-center items-center">
                        <div className="flex mr-auto ml-auto px-5 2xl:w-[450px] xl:w-[400px] lg:w-[400px] md:w-[400px] w-full">
                            <div className="w-full">
                                <img
                                    className="flex mr-auto ml-auto w-[120px]"
                                    src={signinlogo}
                                    alt=""
                                />

                                {/* form */}
                                <div className="mt-10">
                                    <form onSubmit={handleSubmit}>
                                        <div className="w-full relative">
                                            <input
                                                type={viewPwd ? "text" : "password"}
                                                className="w-full bg-[#5D5DDC] text-white h-[45px] shadow-sm border-none placeholder-defaultGray focus:bg-[#5D5DDC] focus:outline-none rounded-[5px] px-5 disabled:cursor-not-allowed"
                                                placeholder="New Password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                required
                                                disabled={isBusy}
                                            />
                                            {
                                                viewPwd ?
                                                    <LuEyeOff className="h-5 w-5 cursor-pointer absolute right-3 bottom-3" onClick={() => setViewPwd(!viewPwd)} />
                                                    :
                                                    <LuEye className="h-5 w-5 cursor-pointer absolute right-3 bottom-3" onClick={() => setViewPwd(!viewPwd)} />
                                            }
                                        </div>

                                        <div className="mt-5">
                                            <input
                                                type={viewPwd ? "text" : "password"}
                                                className="w-full bg-[#5D5DDC] text-white h-[45px] shadow-sm border-none placeholder-defaultGray focus:bg-[#5D5DDC] focus:outline-none rounded-[5px] px-5 disabled:cursor-not-allowed"
                                                placeholder="Confirm Password"
                                                value={confirmPwd}
                                                onChange={(e) => setConfirmPwd(e.target.value)}
                                                required
                                                disabled={isBusy}
                                            />
                                        </div>

                                        <button
                                            type="submit"
                                            className="bg-defaultYellow text-white h-[50px] w-full rounded-[25px] mt-[50px] disabled:cursor-not-allowed"
                                            disabled={isBusy}
                                        >
                                            {
                                                isBusy ?
                                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                                    :
                                                    "Reset"}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
