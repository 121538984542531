import React, { useEffect } from "react";
import signinImg from "../images/signinImg.png";
import signinlogo from "../images/signinlogo.png";
import { useLocation } from "react-router-dom";
import { getCookie, redirect } from "../../helpers/utils";
import { COOKIES_TOKEN_NAME } from "../../helpers/constants";
import { parse } from "querystring";
import SignupForm from "./signupForm";


const SignUp = () => {

  const location = useLocation();

  //onload
  useEffect(() => {

    const redirect_url = parse(location.search.replace("?", ""));
    const token = getCookie(COOKIES_TOKEN_NAME)

    if (token) {
      if (redirect_url?.appref) {
        localStorage.setItem("prevUrl", JSON.stringify(redirect_url?.appref))
        redirect(redirect_url?.appref);
      } else {
        window.location.href = "/account"
      }
    }

    // eslint-disable-next-line
  }, [])

  return (
    <div className="bg-defaultBlue text-white h-screen overflow-hidden">
      <div className="flex justify-center items-center h-full overflow-y-auto">
        <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-2 w-full h-full 2xl:px-[250px] xl:px-[150px] lg:px-[100px] md:px-[70px] px-5 items-center">
          {/* left */}
          <div className="w-full hidden 2xl:block xl:block lg:block md:block">
            <div className="text-left">
              <p className="text-[42px] font-semibold">Sign up</p>

              <p className="text-[16px] mt-[15px] w-[450px]">
                Let's get started by creating your account. To keep your account safe, we advice you add a strong password
              </p>

              <div className="mt-[15px]">
                <img src={signinImg} alt="signin" />
              </div>
            </div>
          </div>

          {/* right */}
          <div className="w-full h-full py-10 flex justify-center items-center">
            <div className="flex mr-auto ml-auto px-5 2xl:w-[500px] xl:w-[450px] lg:w-[450px] md:w-[400px] w-full">
              <div className="w-full">
                <img
                  className="flex mr-auto ml-auto w-[120px]"
                  src={signinlogo}
                  alt="signup"
                />

                {/* form */}
                <SignupForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
