import React, { useEffect, useState } from 'react';
import { message, Spin } from 'antd';
import PinInput from 'react-pin-input';
import signinlogo from "../images/logg.png";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { BASE_URL } from '../../helpers/constants';
import { global_variables } from '../../helpers/globalVariables';
import axios from 'axios';
import ConfettiComponent from '../../components/confetti/ConfettiComponent';
import { parse } from "querystring";


const VerifyNewAccount = () => {

    const [appref, setAppref] = useState<any>("")
    const location = useLocation()
    const navigate = useNavigate()
    const [isBusy, setIsBusy] = useState(false)
    const [theCode, setTheCode] = useState("")
    const [tempEmail, setTempEmail] = useState<any>('')
    const [tempToken, setTempToken] = useState<any>('')
    const [runCongrats, setRunCongrats] = useState(false)

    //onload
    useEffect(() => {
        const redirect_url = parse(location.search.replace("?", ""));
        if (redirect_url?.appref) {
            setAppref(redirect_url?.appref)
        }

        const tempEmail = localStorage.getItem("tempEmail")
        setTempEmail(tempEmail)

        const tempToken = localStorage.getItem("tempToken")
        setTempToken(tempToken)

        // eslint-disable-next-line
    }, [])

    //handlePinComplete
    const handlePinComplete = async () => {
        console.log('Entered PIN:', theCode);
        // Handle PIN verification logic here
        if (theCode.length < 5) {
            message.warning("The code should be 5 digits long")
        } else {
            let theOTP = {
                "token": theCode
            }

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: `${BASE_URL}${global_variables.verify_account}`,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': tempToken
                },
                data: theOTP
            };

            setIsBusy(true)

            try {
                const response = await axios.request(config)
                console.log(response?.data)
                message.success(response?.data?.message || "Your account has been verified. Login to proceed")
                setRunCongrats(true)
                setTimeout(() => {
                    setIsBusy(false)
                    setRunCongrats(false)
                    if (appref) {
                        navigate(`/?appref=${appref}`)
                    } else {
                        navigate("/")
                    }
                }, 3000)
            } catch (error: any) {
                console.log("verifyErr:", error)
                message.error(error?.response?.data?.error || "An unexpected error occured. Please login")
                setIsBusy(false)
                navigate("/")
            }
        }
    };

    return (
        <div className="flex flex-col justify-center items-center min-h-screen bg-[#F7F7FF]">
            <ConfettiComponent run={runCongrats} />

            <div className="">
                <img src={signinlogo} alt="." className="mx-auto mb-5" />
            </div>

            <div className="bg-white p-10 rounded-[20px] shadow-lg w-[400px]">
                <div className="text-center mb-5">
                    <h2 className="text-2xl font-bold text-[#6754D6]">Verify email</h2>
                    <p className="text-gray-600">We've sent a code to <strong>{tempEmail || "your email"}</strong></p>
                </div>

                <div className="w-full">
                    <div className="flex justify-center mb-5">
                        <PinInput
                            length={5}
                            focus
                            type="numeric"
                            // onComplete={handlePinComplete}
                            onChange={(val) => setTheCode(val)}
                            inputStyle={{ borderColor: '#D1D5DB', borderRadius: '8px', width: '45px', height: '45px', margin: '0 5px' }}
                            inputFocusStyle={{ borderColor: '#6754D6' }}
                            disabled={isBusy}
                        />
                    </div>
                    <div className="text-center mb-5 text-gray-600">
                        <p>I didn't receive a code.. <Link to="#" className="text-defaultBlue">Resend</Link></p>
                    </div>
                    <div className="text-center">
                        <button className="h-[45px] w-full rounded-full bg-defaultBlue text-white disabled:cursor-not-allowed" onClick={handlePinComplete} disabled={isBusy}>
                            {
                                isBusy ?
                                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                    :
                                    <p>Verify</p>
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyNewAccount;
