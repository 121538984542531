import { COOKIES_TOKEN_NAME } from "../helpers/constants";
import { getCookie } from "../helpers/utils";

const ProtectedRoutes = ({ children }: any) => {
    const token = getCookie(COOKIES_TOKEN_NAME);

    if (token) {
        return children;
    } else {
        return window.location.href = "/"
    }
};

export default ProtectedRoutes;
