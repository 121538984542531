import React, { useEffect, useState } from 'react'
import { Checkbox, DatePicker, Form, Input, message, Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import PhoneInput from "react-phone-number-input";
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';
import { parse } from "querystring";
import TermsAndConditions from '../termsAndPolicy/termsAndConditions';


const SignupForm = () => {

    const location = useLocation()
    const [appref, setAppref] = useState<any>("")
    const [isBusy, setIsBusy] = useState(false)
    const [thePhoneNumber, setThePhoneNumber] = useState("");

    const [selectedTab, setSelectedTab] = useState('Parent');
    const tabs = ['Learner', 'Parent', 'Educator'];

    const [openTermsModal, setOpenTermsModal] = useState(false)

    //onload
    useEffect(() => {

        const redirect_url = parse(location.search.replace("?", ""));

        if (redirect_url?.appref) {
            setAppref(redirect_url?.appref)
        }

        // eslint-disable-next-line
    }, [])

    //onFinish
    const onFinish = async (values: any) => {
        delete values?.agreement;
        delete values?.confirmPassword;
        // Format the date of birth
        if (values?.dob) {
            values.dob = values.dob.format('YYYY-MM-DD');
        }

        // Remove middleName if empty
        if (!values?.middleName) {
            delete values?.middleName;
        }

        console.log('Form Values:', values);
        localStorage.setItem("tempEmail", values?.email)
        setIsBusy(true)

        try {
            const response = await BaseService.post_api(global_variables.signup, values)
            console.log("signUpRes:", response?.data)
            localStorage.setItem("tempToken", response?.data?.token)
            message.success(response?.data?.message || "Verification token has been sent to your email")
            setTimeout(() => {
                window.location.href = `${appref ? `/verification?appref=${appref}` : '/verification'}`
            }, 1000)
        } catch (error: any) {
            console.log("signUpErr:", error)
            setIsBusy(false)
            message.error(error?.response?.data?.error || "Error while creating account. Try again")
        }
    };

    return (
        <>
            <div className="w-full">
                {/* tabs */}
                <div className="w-full px-5">
                    <div className="w-full flex px-[40px] justify-between items-center bg-[#F0EFF8] mt-5 rounded-full">
                        {tabs.map((tab) => (
                            <div
                                key={tab}
                                className={`flex-1 text-center p-3 cursor-pointer ${selectedTab === tab ? 'bg-[#FFBF00]' : 'bg-transparent'
                                    }`}
                                onClick={() => setSelectedTab(tab)}
                            >
                                <p className={`font-bold ${selectedTab === tab ? 'text-white' : 'text-[#6754D6]'}`}>
                                    {tab}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* forms */}
                <div className="w-full bg-white text-defaultText p-10 rounded-[20px] mt-5">
                    <Form
                        name="register"
                        className="w-full"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        disabled={isBusy}
                    >
                        <Form.Item
                            name="firstName"
                            rules={[{ required: true, message: 'Please input your first name!' }]}
                        >
                            <Input className='h-[40px]' placeholder="First Name" />
                        </Form.Item>

                        <div className="flex space-x-2">
                            <Form.Item
                                name="middleName"
                                rules={[{ required: false }]}
                                className="flex-1"
                            >
                                <Input className='h-[40px]' placeholder="Middle Name" />
                            </Form.Item>

                            <Form.Item
                                name="lastName"
                                rules={[{ required: true, message: 'Please input your last name!' }]}
                                className="flex-1"
                            >
                                <Input className='h-[40px]' placeholder="Last Name" />
                            </Form.Item>
                        </div>

                        <Form.Item
                            name="dob"
                            rules={[{ required: true, message: 'Please input your date of birth!' }]}
                        >
                            <DatePicker className='h-[40px] w-full' format='YYYY-MM-DD' placeholder="Date of birth" />
                        </Form.Item>

                        <Form.Item
                            name="gender"
                            rules={[{ required: true, message: 'Please select your gender!' }]}
                        >
                            <Select className='h-[40px] w-full' placeholder="Gender" options={[
                                { label: "Male", value: "M" },
                                { label: "Female", value: "F" },
                                { label: "Rather not say", value: "O" }
                            ]} />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your email!' }]}
                        >
                            <Input className='h-[40px]' placeholder="Enter your email" />
                        </Form.Item>

                        <div className="flex space-x-2">
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password className='h-[40px]' placeholder="Password" />
                            </Form.Item>

                            <Form.Item
                                name="confirmPassword"
                                rules={[{ required: true, message: 'Please confirm password!' }]}
                            >
                                <Input.Password className='h-[40px]' placeholder="Confirm Password" />
                            </Form.Item>
                        </div>

                        <Form.Item
                            name="phone"
                            rules={[{ required: true, message: 'Please input your phone number!' }]}
                        >
                            <PhoneInput
                                id="phone"
                                className="w-full border border-gray-300 rounded-[5px] px-2 h-[40px]"
                                defaultCountry="GH"
                                inputStyle={{ width: "100%" }}
                                value={thePhoneNumber}
                                onChange={(phone: any) => setThePhoneNumber(phone)}
                                numberInputProps={{
                                    className: "focus:outline-none",
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="agreement"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject('Please accept agreement'),
                                },
                            ]}
                        >
                            <Checkbox>
                                By submitting I acknowledge that I have read and do hereby accept the terms and conditions in the RoboCentre's <Link to="#" className='text-defaultBlue' onClick={() => setOpenTermsModal(true)}>Terms of Use</Link> and <Link to="#" className='text-defaultBlue'>Privacy Policy</Link>
                            </Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <button
                                type="submit"
                                className="bg-defaultBlue text-white h-[50px] w-full rounded-[25px] mt-[20px] disabled:cursor-not-allowed"
                                disabled={isBusy}
                            >
                                {
                                    isBusy ?
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                        :
                                        <p>Sign Up</p>
                                }
                            </button>
                        </Form.Item>

                        <p className="text-center text-defaultText text-[16px] font-[400]">
                            Already signed up? <Link to={`${appref ? `/?appref=${appref}` : '/'}`} className='text-defaultBlue'>Log In</Link>
                        </p>
                    </Form>
                </div>
            </div>


            {/* terms modal */}
            {
                openTermsModal && <TermsAndConditions isOpened={openTermsModal} handleClose={() => setOpenTermsModal(false)} />
            }
        </>
    )
}

export default SignupForm
