import React, { useState } from "react";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { LoadingOutlined } from '@ant-design/icons';
import { message, notification, Spin } from 'antd';
import BaseService from "../../helpers/baseServices";
import { global_variables } from "../../helpers/globalVariables";
import { redirect, setCookie, setCookieJson } from "../../helpers/utils";
import { COOKIES_TOKEN_NAME, COOKIES_USER_DATA, COOKIES_USER_VERIFIED } from "../../helpers/constants";
import { useLocation } from "react-router-dom";
import { parse } from "querystring";


const SignInForm = () => {

  const location = useLocation();

  const redirect_url = parse(location.search.replace("?", ""));
  const [isBusy, setIsBusy] = useState(false);

  //input values
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [viewPwd, setViewPwd] = useState(false)


  //handle submit function
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let signin_data = {
      "email": email,
      "password": password
    }

    console.log("----signinForm:", signin_data)

    setIsBusy(true)

    try {
      const response = await BaseService.post_api(global_variables.signin, signin_data)
      console.log("signinRes:", response?.data)
      setCookie(COOKIES_TOKEN_NAME, response?.data?.token)
      setCookieJson(COOKIES_USER_DATA, response?.data?.payload)
      setCookie(COOKIES_USER_VERIFIED, response?.data?.verification)
      notification.success({
        message: `Logged in as ${response.data?.payload?.firstName}`,
      });

      if (redirect_url?.appref) {
        redirect(redirect_url?.appref);
      } else {
        window.location.href = "/account";
      }

      setIsBusy(false)

    } catch (error: any) {
      console.log("----signinErr:", error)
      message.error(error?.response?.data?.error || "An unexpected error occured. Try again")
      setIsBusy(false)
    }

  };


  return (
    <div className="mt-10">
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="email"
            className="w-full bg-[#5D5DDC] text-white h-[45px] shadow-sm border-none placeholder-defaultGray focus:bg-[#5D5DDC] focus:outline-none rounded-[5px] px-5 disabled:cursor-not-allowed"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isBusy}
          />
        </div>

        <div className="mt-[20px] relative">
          <input
            type={viewPwd ? "text" : "password"}
            className="w-full bg-[#5D5DDC] text-white h-[45px] shadow-sm border-none placeholder-defaultGray focus:bg-[#5D5DDC] focus:outline-none rounded-[5px] px-5 disabled:cursor-not-allowed"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
            disabled={isBusy}
          />
          {
            viewPwd ?
              <LuEyeOff className="h-5 w-5 cursor-pointer absolute right-3 bottom-3" onClick={() => setViewPwd(!viewPwd)} />
              :
              <LuEye className="h-5 w-5 cursor-pointer absolute right-3 bottom-3" onClick={() => setViewPwd(!viewPwd)} />
          }
        </div>

        <button
          type="submit"
          className="bg-defaultYellow text-white h-[50px] w-full rounded-[25px] mt-[50px] disabled:cursor-not-allowed"
          disabled={isBusy}
        >
          {
            isBusy ?
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
              :
              "Log In"}
        </button>
      </form>
    </div>
  );
};

export default SignInForm;
