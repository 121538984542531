import React, { useEffect, useState } from "react";
import signinImg from "../images/signinImg.png";
import signinlogo from "../images/signinlogo.png";
import { Link, useLocation } from "react-router-dom";
import SignInForm from "./SignInForm";
import { getCookie, redirect } from "../../helpers/utils";
import { COOKIES_TOKEN_NAME } from "../../helpers/constants";
import { parse } from "querystring";


const SignIn = () => {

  const location = useLocation();
  const [appref, setAppref] = useState<any>("")

  //onload
  useEffect(() => {

    const redirect_url = parse(location.search.replace("?", ""));
    const token = getCookie(COOKIES_TOKEN_NAME)

    setAppref(redirect_url?.appref)

    if (token) {
      if (redirect_url?.appref) {
        redirect(redirect_url?.appref);
      } else {
        window.location.href = "/account"
      }
    }

    // eslint-disable-next-line
  }, [])

  return (
    <div className="bg-defaultBlue text-white h-screen overflow-hidden">
      <div className="flex justify-center items-center h-full">
        <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-2 w-full 2xl:px-[250px] xl:px-[150px] lg:px-[100px] md:px-[70px] px-5">
          {/* left */}
          <div className="w-full hidden 2xl:block xl:block lg:block md:block">
            <div className="text-left">
              <p className="text-[42px] font-semibold">Log In</p>

              <p className="text-[16px] mt-[15px] w-[450px]">
                Pick up right where you left off by signing into your account. Simply enter your email and password to continue.
              </p>

              <div className="mt-[15px]">
                <img src={signinImg} alt="signin" />
              </div>
            </div>
          </div>

          {/* right */}
          <div className="w-full py-10 flex justify-center items-center">
            <div className="flex mr-auto ml-auto px-5 2xl:w-[450px] xl:w-[400px] lg:w-[400px] md:w-[400px] w-full">
              <div className="w-full">
                <img
                  className="flex mr-auto ml-auto w-[120px]"
                  src={signinlogo}
                  alt="signin"
                />

                {/* form */}
                <SignInForm />

                <div className="mt-[20px] text-white">
                  <div className="flex items-center justify-center gap-2">
                    <p className="text-[16px]">
                      Forgot password?
                    </p>
                    <Link to={`${appref ? `/password-reset?appref=${appref}` : '/password-reset'}`} className="hover:text-white hover:underline">
                      Reset Password
                    </Link>
                  </div>
                  <div className="flex items-center justify-center gap-2 mt-[20px]">
                    <p className="text-[16px]">
                      New to Robocentre?
                    </p>
                    <Link to={`${appref ? `/signup?appref=${appref}` : '/signup'}`} className="hover:text-white hover:underline">
                      Sign Up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
