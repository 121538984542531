import React, { useEffect, useState } from 'react'
import { Modal, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import BaseService from '../../helpers/baseServices';
import { global_variables } from '../../helpers/globalVariables';


interface modalProps {
    isOpened: boolean
    handleClose: () => void
}


const TermsAndConditions = ({ isOpened, handleClose }: modalProps) => {

    const [isBusy, setIsBusy] = useState(true)

    //onload
    useEffect(() => {

        const getTheTerms = async () => {
            setIsBusy(true)

            try {
                const response = await BaseService.get_api(`${global_variables.terms_and_policies}`)
                console.log(response)
                setIsBusy(false)
            } catch (error) {
                console.log(error)
                setIsBusy(false)
            }
        }

        getTheTerms()

    }, [])

    return (
        <>
            <Modal width={1200} open={isOpened} onCancel={handleClose} footer={null} centered>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 38, color: "#6666EA" }} spin />} spinning={isBusy}>
                    <div className='w-full p-5'>
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis quidem sed beatae optio id, delectus incidunt maiores dolorem voluptate cum! Assumenda, libero perferendis. Adipisci voluptatum amet est! Incidunt, architecto porro.
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis quidem sed beatae optio id, delectus incidunt maiores dolorem voluptate cum! Assumenda, libero perferendis. Adipisci voluptatum amet est! Incidunt, architecto porro.
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis quidem sed beatae optio id, delectus incidunt maiores dolorem voluptate cum! Assumenda, libero perferendis. Adipisci voluptatum amet est! Incidunt, architecto porro.
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis quidem sed beatae optio id, delectus incidunt maiores dolorem voluptate cum! Assumenda, libero perferendis. Adipisci voluptatum amet est! Incidunt, architecto porro.
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis quidem sed beatae optio id, delectus incidunt maiores dolorem voluptate cum! Assumenda, libero perferendis. Adipisci voluptatum amet est! Incidunt, architecto porro.
                        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nobis quidem sed beatae optio id, delectus incidunt maiores dolorem voluptate cum! Assumenda, libero perferendis. Adipisci voluptatum amet est! Incidunt, architecto porro.
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default TermsAndConditions
