import React from "react";
import ProfilePic from "./ProfilePic";
import logoImg from "../../images/logo.png"

const CustomNavbar = () => {
  return (
    <div className="2xl:px-[120px] xl:px-[96px] lg:px-[96px] md:px-[50px] px-[16px] py-[20px] flex justify-between items-center bg-white shadow-md w-full">
      <div>
        <img src={logoImg} alt="" />
      </div>

      <div>
        <ProfilePic />
      </div>
    </div>
  );
};

export default CustomNavbar;
