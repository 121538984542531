import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import BaseService from '../helpers/baseServices';
import { global_variables } from '../helpers/globalVariables';
import { getCookie, setCookieJson } from '../helpers/utils';
import { COOKIES_TOKEN_NAME, COOKIES_USER_DATA } from '../helpers/constants';


// Create the context
const GeneralContext = createContext<any>(null);

// Create a custom hook for easy access
export const useGeneralRequests = () => useContext(GeneralContext);

interface ApiProviderProps {
    children: ReactNode;
}

export const GeneralContextProvider: React.FC<ApiProviderProps> = ({ children }) => {

    const token = getCookie(COOKIES_TOKEN_NAME)
    const [fetchUserData, setFetchUSerInfo] = useState(false)
    const [userData, setUserData] = useState<any>(null)

    //getUserData
    const getUserData = async () => {
        try {
            const response = await BaseService.get_api(global_variables.get_user_details)
            console.log("userDataRes:", response?.data)
            setUserData(response?.data?.payload)
            setCookieJson(COOKIES_USER_DATA, response?.data?.payload)
        } catch (error: any) {
            console.log("userDataErr:", error)
        }
    }

    //onload
    useEffect(() => {
        if (token) {
            getUserData()
        }

        // eslint-disable-next-line
    }, [fetchUserData])

    return (
        <GeneralContext.Provider value={{ setFetchUSerInfo, userData, setUserData, getUserData }}>
            {children}
        </GeneralContext.Provider>
    );
};
