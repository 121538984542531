const route = "/robocentre";

export const global_variables = {
  signin: `${route}/auth/signin`,
  signup: `${route}/auth/signup`,
  verify_account: `${route}/auth/verify_account`,
  forgetpassword: `${route}/auth/forgetpassword`,
  resetpassword: `${route}/auth/resetpassword`,

  //user
  get_user_details: `${route}/user/details`,
  update_user_details: `${route}/user/details`,

  //terms and policies
  terms_and_policies: "/robocentre/terms-condition-policy",
};
