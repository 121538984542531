import React from 'react'
import { Input } from 'antd'


const ChangePassword = () => {
    return (
        <div className='w-full'>
            <p className='text-[30px] font-[600] text-defaultText'>Change Password</p>

            <div className="flex justify-center items-center h-full mt-[24px]">
                <div className='2xl:w-[400px] xl:w-[400px] lg:w-[400px] md:w-[400px] w-full px-5 text-defaultText'>
                    <div className='w-full'>
                        <label htmlFor="curr" className='text-[16px] font-[400]'>
                            Current Password
                        </label>
                        <Input id='curr' className='h-[50px] w-full' />
                    </div>

                    <div className='w-full mt-[24px]'>
                        <label htmlFor="curr" className='text-[16px] font-[400]'>
                            New Password
                        </label>
                        <Input id='curr' className='h-[50px] w-full' />
                    </div>

                    <div className='w-full mt-[24px]'>
                        <label htmlFor="curr" className='text-[16px] font-[400]'>
                            Retype New Password
                        </label>
                        <Input id='curr' className='h-[50px] w-full' />
                    </div>

                    <div className='w-full my-[24px]'>
                        <button className='h-[45px] w-full rounded-[30px] bg-defaultBlue text-white'>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword
