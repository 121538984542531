import React, { useEffect, useState } from "react";
import signinImg from "../images/signinImg.png";
import signinlogo from "../images/signinlogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { message, Spin } from "antd";
import PinInput from 'react-pin-input';
import { LoadingOutlined } from '@ant-design/icons';
import ShowAlert from "../../components/alerts/all_alerts";
import { BASE_URL } from "../../helpers/constants";
import axios from "axios";
import { global_variables } from "../../helpers/globalVariables";
import { parse } from "querystring";


const VerifyAccount = () => {

    const location = useLocation();
    const [appref, setAppref] = useState<any>("")

    const navigate = useNavigate()
    const [isBusy, setIsBusy] = useState(false);
    const [theCode, setTheCode] = useState("")


    //onload
    useEffect(() => {
        const redirect_url = parse(location.search.replace("?", ""));
        if (redirect_url) {
            setAppref(redirect_url?.appref)
        }

        // eslint-disable-next-line
    }, [])


    //handle verify function
    const handleVerify = async () => {

        if (theCode.length < 5) {
            message.warning("Please enter the correct code provided")
        } else {

            let verify_data = {
                "token": theCode
            }

            console.log("---ready", verify_data)
            setIsBusy(true)

            let config = {
                method: 'put',
                maxBodyLength: Infinity,
                url: `${BASE_URL}${global_variables.verify_account}`,
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem("tempToken")
                },
                data: verify_data
            };

            try {
                const response = await axios.request(config)
                console.log("---verifyAccRes:", response?.data)
                localStorage.setItem("tempToken", response?.data?.token)
                navigate(`${appref ? `/reset-password?appref=${appref}` : '/reset-password'}`)
            } catch (error: any) {
                console.log("---verifyAccErr:", error)
                setIsBusy(false)
                ShowAlert.error_alert("Error", error?.response?.data?.error || "An unexpected error occured. Try again", "Try Again")
                    .then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = `${appref ? `/?appref=${appref}` : '/'}`
                        }
                    })
            }
        }

    };


    return (
        <div className="bg-defaultBlue text-white h-screen overflow-hidden">
            <div className="flex justify-center items-center h-full">
                <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-2 w-full 2xl:px-[150px] xl:px-[150px] lg:px-[100px] md:px-[70px] px-5">
                    {/* left */}
                    <div className="w-full hidden 2xl:block xl:block lg:block md:block">
                        <div className="text-left">
                            <p className="text-[42px] font-semibold">Verify your email</p>

                            <p className="text-[16px] mt-[15px]">
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
                            </p>

                            <div className="mt-[15px]">
                                <img src={signinImg} alt="" />
                            </div>
                        </div>
                    </div>

                    {/* right */}
                    <div className="w-full py-10 flex justify-center items-center">
                        <div className="flex mr-auto ml-auto px-5 2xl:w-[450px] xl:w-[400px] lg:w-[400px] md:w-[400px] w-full">
                            <div className="w-full">
                                <img
                                    className="flex mr-auto ml-auto w-[120px]"
                                    src={signinlogo}
                                    alt=""
                                />

                                {/* form */}
                                <div className="mt-10 flex justify-center">
                                    <PinInput
                                        length={5}
                                        secret
                                        secretDelay={100}
                                        onChange={(value, index) => setTheCode(value)}
                                        type="numeric"
                                        inputMode="number"
                                        style={{ padding: '5px' }}
                                        inputStyle={{ borderColor: '#fff', borderRadius: '10px', height: '60px', width: '60px' }}
                                        inputFocusStyle={{ borderColor: '#FFC400' }}
                                        // onComplete={(value, index) => handleVerification(value)}
                                        autoSelect={true}
                                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                        disabled={isBusy}
                                    />
                                </div>

                                <button
                                    onClick={handleVerify}
                                    className="bg-defaultYellow text-white h-[50px] w-full rounded-[25px] mt-[50px] disabled:cursor-not-allowed"
                                    disabled={isBusy}
                                >
                                    {
                                        isBusy ?
                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                            :
                                            "Verify Account"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyAccount;
