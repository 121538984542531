import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import SignIn from "./pages/signin/SignIn";
import MyAccount from "./pages/myAccount/myAccount";
import ProtectedRoutes from "./router/router";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import VerifyAccount from "./pages/forgotPassword/verifyAccount";
import ResetPassword from "./pages/forgotPassword/resetPassword";
import { GeneralContextProvider } from "./context/generalContext";
import SignUp from "./pages/signup/SignUp";
import VerifyNewAccount from "./pages/verification/verifyAccount";

function App() {
  return (
    <GeneralContextProvider>
      <Router>
        <Routes>
          <Route path="/*" element={<h4>Page not found!!</h4>} />
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verification" element={<VerifyNewAccount />} />
          <Route path="/password-reset" element={<ForgotPassword />} />
          <Route path="/verify-account" element={<VerifyAccount />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/account" element={<ProtectedRoutes><MyAccount /></ProtectedRoutes>} />
        </Routes>
      </Router>
    </GeneralContextProvider>
  );
}

export default App;
